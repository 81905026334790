.spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.gegga {
  width: 0;
}
.snurra {
  filter: url(#gegga);
}
.stopp1 {
  stop-color: #f700a8;
}
.stopp2 {
  stop-color: #ff8000;
}
.halvan {
  animation: Snurra1 10s infinite linear;
  stroke-dasharray: 180 800;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}
.strecken {
  animation: Snurra1 3s infinite linear;
  stroke-dasharray: 26 54;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}
.skugga {
  filter: blur(0.313rem);
  opacity: 0.3;
  position: absolute;
  transform: translate(0.188rem, 0.188rem);
}
@keyframes Snurra1 {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -25.188rem;
  }
}
